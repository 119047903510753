// src/pages/ConviertetePartner.js
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ConviertetePartner.css';

const ConviertetePartner = () => {
  const [formData, setFormData] = useState({
    nombreEmpresa: '',
    nombreContacto: '',
    emailCorporativo: '',
    telefono: '',
    tipoPartnership: '',
    descripcion: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 800, easing: 'ease-in-out', once: true });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación rápida
    if (!formData.nombreEmpresa || !formData.emailCorporativo) {
      alert('Por favor completa los campos requeridos');
      return;
    }

    try {
      // Ajusta la URL a tu endpoint real o método de envío
      await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
      alert('Error al enviar la información. Inténtalo nuevamente.');
    }
  };

  if (isSubmitted) {
    return (
      <section className="partner-container" data-aos="fade-up">
        <h2>¡Gracias por tu interés en colaborar!</h2>
        <p>Hemos recibido tus datos y te contactaremos a la brevedad.</p>
      </section>
    );
  }

  return (
    <section className="partner-container" data-aos="fade-up">
      <h2 className="partner-heading">Conviértete en Partner</h2>
      <p className="partner-description">
        En <strong>Soluciones Mayores</strong> valoramos la colaboración con empresas y profesionales
        que compartan nuestra visión. ¡Conversemos acerca de cómo podemos crecer juntos!
      </p>

      <form onSubmit={handleSubmit} className="partner-form">
        <div className="form-group">
          <label htmlFor="nombreEmpresa">Nombre de la Empresa o Persona *</label>
          <input
            type="text"
            id="nombreEmpresa"
            name="nombreEmpresa"
            value={formData.nombreEmpresa}
            onChange={handleChange}
            placeholder="Ej. Tech Innovations Ltda."
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="nombreContacto">Nombre del Representante / Contacto</label>
          <input
            type="text"
            id="nombreContacto"
            name="nombreContacto"
            value={formData.nombreContacto}
            onChange={handleChange}
            placeholder="Ej. Carla Soto"
          />
        </div>

        <div className="form-group">
          <label htmlFor="emailCorporativo">Correo Electrónico Corporativo *</label>
          <input
            type="email"
            id="emailCorporativo"
            name="emailCorporativo"
            value={formData.emailCorporativo}
            onChange={handleChange}
            placeholder="contacto@empresa.com"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="telefono">Teléfono de Contacto</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            placeholder="+56 9 8765 4321"
          />
        </div>

        <div className="form-group">
          <label htmlFor="tipoPartnership">Tipo de Partnership</label>
          <select
            id="tipoPartnership"
            name="tipoPartnership"
            value={formData.tipoPartnership}
            onChange={handleChange}
          >
            <option value="">Selecciona una opción</option>
            <option value="Distribución">Distribución</option>
            <option value="Comercial">Comercial</option>
            <option value="Tecnológico">Tecnológico</option>
            <option value="Otro">Otro</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="descripcion">
            Breve descripción de la colaboración propuesta
          </label>
          <textarea
            id="descripcion"
            name="descripcion"
            rows="4"
            value={formData.descripcion}
            onChange={handleChange}
            placeholder="¿Por qué deseas colaborar con Soluciones Mayores?"
          />
        </div>

        <div className="submit-btn-container">
          <button type="submit" className="btn-success">Enviar</button>
        </div>
      </form>
    </section>
  );
};

export default ConviertetePartner;
