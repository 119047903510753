// src/pages/CentroDeAyuda.js
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './CentroDeAyuda.css';

const CentroDeAyuda = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 800, easing: 'ease-in-out', once: true });
  }, []);

  const faqs = [
    {
      pregunta: '¿Qué es SOMA y cómo funciona?',
      respuesta: 'SOMA es nuestro software principal para administrar residencias de adultos mayores. Funciona en la nube, permitiendo gestionar de forma centralizada la información de cada residente, historiales médicos, datos de contacto y más.'
    },
    {
      pregunta: '¿Cómo puedo obtener soporte técnico?',
      respuesta: 'Puedes contactarnos vía correo o chat en vivo dentro del sistema. Nuestro equipo de soporte está disponible de lunes a viernes, de 9:00 a 18:00 hrs.'
    },
    {
      pregunta: '¿Puedo usar SOMA desde mi teléfono celular?',
      respuesta: 'Sí, SOMA es responsive y se adapta a la mayoría de dispositivos móviles. Además, contamos con planes futuros de lanzar una app nativa.'
    },
    {
      pregunta: '¿Qué costo tiene la implementación de SOMA?',
      respuesta: 'Los costos varían según los módulos que elijas y la cantidad de residencias que administres. Te recomendamos revisar nuestra sección de registro o solicitar una cotización personalizada.'
    }
    // Agrega más FAQ según requieras...
  ];

  const toggleFAQ = (index) => {
    setOpenFAQ((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="ayuda-container" data-aos="fade-up">
      <h2 className="ayuda-heading">Centro de Ayuda</h2>
      <p className="ayuda-description">
        Bienvenido a nuestro Centro de Ayuda. Despliega las preguntas para encontrar
        información relevante sobre <strong>SOMA</strong> y los servicios de
        <strong> Soluciones Mayores</strong>.
      </p>

      <div className="faq-section">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button
              className={`faq-question ${openFAQ === index ? 'active' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.pregunta}
            </button>
            <div
              className="faq-answer"
              style={{ display: openFAQ === index ? 'block' : 'none' }}
            >
              <p>{faq.respuesta}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CentroDeAyuda;
