// SobreNosotros.js
import React from 'react';
import './SobreNosotros.css';

// Importar imágenes (ajusta rutas según tu proyecto)
import martinImg from '../assets/img/integrante1.jpeg';
import alejandroImg from '../assets/img/integrante2.jpg';

const SobreNosotros = () => {
  return (
    <div className="sobre-nosotros">
      <h1>Nuestra Historia, Nuestra Misión</h1>

      <p>
        En <strong>Soluciones Mayores</strong>, creemos en el poder de la tecnología
        para impulsar una <em>nueva era</em> en la atención y el cuidado de los adultos
        mayores. Nuestro lema, <strong>“Soluciones para el cuidado, tecnologías para el futuro”</strong>,
        resume nuestro propósito: ofrecer herramientas innovadoras que beneficien no
        solo a las residencias, sino a todo el ecosistema que rodea a las personas mayores,
        incluyendo familias, cuidadores y profesionales de la salud.
      </p>
      <p>
        Nuestra inspiración nace de las historias de padres, abuelos y amigos que nos
        han mostrado, de primera mano, la necesidad de **soluciones** capaces  de
        <em> simplificar</em> y <em>dignificar</em> cada etapa de la vida. Por eso,
        desarrollamos productos que combinan tecnología de vanguardia y una
        profunda <strong>empatía</strong> hacia quienes más lo necesitan.
      </p>

      <div className="valores">
        <h2>Nuestros Valores</h2>
        <ul>
          <li>
            <strong>Compromiso:</strong> Estamos al lado de los adultos mayores y todos
            los actores de su entorno, asegurando que nuestras soluciones realmente
            transformen su vida diaria.
          </li>
          <li>
            <strong>Innovación:</strong> “Soluciones para el cuidado, tecnologías para el futuro”
            no es solo un eslogan, sino la base de nuestra búsqueda constante por romper
            las barreras tecnológicas y mejorar la experiencia de envejecimiento.
          </li>
          <li>
            <strong>Empatía:</strong> Diseñamos con la mirada puesta en las personas:
            escuchamos, comprendemos y adaptamos cada detalle a las realidades y desafíos
            del adulto mayor.
          </li>
          <li>
            <strong>Integridad:</strong> Operamos con honestidad y transparencia en todas
            nuestras alianzas y desarrollos, confiando en que el trabajo ético es la clave
            para un impacto sostenible.
          </li>
        </ul>
      </div>

      <div className="equipo">
        <h2>Conoce a Nuestro Equipo</h2>
        <p>
          Somos una <strong>startup</strong> con la mirada puesta en el futuro de la
          atención geriátrica y familiar. Descubre quiénes conforman <strong>Soluciones Mayores</strong> 
          y por qué creemos que un <em>envejecimiento activo y digno</em> es posible cuando
          la tecnología se pone al servicio de las personas.
        </p>

        <div className="equipo-cards">
          {/* Tarjeta: Martin Zulian */}
          <div className="equipo-card">
            <div className="equipo-img">
              <img
                src={martinImg}
                alt="Martin Zulian - CEO & Co-Fundador"
              />
            </div>
            <h3>Martin Zulian</h3>
            <p className="equipo-rol">
              Ingeniero Informático | Director de Operaciones y Proyectos <br />
              CEO & Co-Fundador
            </p>
            <p className="equipo-descripcion">
              Con una sólida trayectoria en la industria tecnológica,
              Martin lidera la visión estratégica de <strong>Soluciones Mayores</strong>.
              Su meta es llevar la innovación a cada rincón del ecosistema que rodea
              al adulto mayor, garantizando soluciones de alto impacto y fáciles de usar.
            </p>
          </div>

          {/* Tarjeta: Alejandro Farías */}
          <div className="equipo-card">
            <div className="equipo-img">
              <img
                src={alejandroImg}
                alt="Alejandro Farías - Co-Fundador"
              />
            </div>
            <h3>Alejandro Farías</h3>
            <p className="equipo-rol">
              Ingeniero Informático | Co-Fundador
            </p>
            <p className="equipo-descripcion">
              Alejandro es el motor de la transformación digital en cada uno de nuestros
              proyectos. Su pasión por el desarrollo de software y la capacidad de
              traducir necesidades en soluciones tecnológicas confiables, lo convierten
              en un pilar fundamental para materializar nuestra visión de futuro.
            </p>
          </div>

          {/* Se ha eliminado la tarjeta del tercer integrante */}
        </div>
      </div>

      <div className="seccion-extra">
        <h2>Nuestra Visión a Futuro</h2>
        <p>
          Con proyectos como <strong>SOMA</strong> —herramienta clave en la administración
          de residencias— y nuevas iniciativas como <strong>Geras</strong>, dirigidas
          a extender el apoyo al adulto mayor en cada etapa, seguimos un camino claro
          hacia la innovación social y la inclusión digital. Queremos que cada avance
          tecnológico sea un paso hacia una vejez más plena y participativa.
        </p>
        <p>
          En <strong>Soluciones Mayores</strong>, estamos convencidos de que lo mejor
          está por venir. Con la fuerza de las ideas y la pasión de nuestro equipo,
          imaginamos un futuro en el que envejecer sea sinónimo de calidad de vida,
          compañía y acceso a soluciones que abran nuevas posibilidades.
        </p>
      </div>
    </div>
  );
};

export default SobreNosotros;
