// NewModulesSection.js
import React from 'react';
import './NewModulesSection.css';

const NewModulesSection = () => {
  return (
    <section 
      className="new-modules-section" 
      id="nuevos-modulos"
      data-aos="fade-up" 
      data-aos-duration="1000" 
      data-aos-delay="200"
    >
      <div className="section-container">
        <h2 className="section-title" data-aos="fade-up">
          Potencia tu Residencia con las Extensiones de SOMA
        </h2>

        <div className="cards-container">
          {/* Tarjeta del Portal de Apoderados */}
          <div 
            className="module-card" 
            data-aos="fade-up" 
            data-aos-duration="1000" 
            data-aos-delay="200"
          >
            <div className="card-content">
              <h3 className="card-title">
                Portal de Apoderados
              </h3>
              <p className="card-description">
                Con <strong>SOMA</strong> como base, en <strong>Soluciones Mayores</strong> hemos desarrollado el 
                <strong> Portal de Apoderados</strong> para mantener a las familias 
                conectadas e informadas. Esta extensión se integra a la perfección con 
                nuestra plataforma principal, ofreciendo un espacio seguro y personalizado 
                donde los apoderados pueden monitorear el bienestar de sus seres queridos 
                en todo momento.
              </p>
              <ul className="features-list">
                <li><strong>Integración Total con SOMA:</strong> Sincroniza automáticamente la información de los residentes, eliminando duplicidades y manteniendo la coherencia de datos.</li>
                <li><strong>Acceso Seguro y Personalizado:</strong> Cada familiar ingresa con credenciales propias para ver información relevante de su ser querido.</li>
                <li><strong>Actualizaciones en Tiempo Real:</strong> Observa cambios en el estado de salud, actividades o progresos, todo desde un panel intuitivo.</li>
                <li><strong>Comunicación Directa:</strong> Envía y recibe mensajes con el equipo de cuidado, fortaleciendo el vínculo entre la residencia y los apoderados.</li>
              </ul>
            </div>
          </div>

          {/* Tarjeta del Portal del Cuidador */}
          <div 
            className="module-card" 
            data-aos="fade-up" 
            data-aos-duration="1000" 
            data-aos-delay="200"
          >
            <div className="card-content">
              <h3 className="card-title">
                Portal del Cuidador
              </h3>
              <p className="card-description">
                El trabajo de los cuidadores es esencial para garantizar una atención 
                de calidad. Con esa visión, presentamos el <strong>Portal del Cuidador</strong>,
                una herramienta adicional a <strong>SOMA</strong> que les facilita la 
                recolección y seguimiento de información diaria, así como el intercambio 
                de datos con el resto del equipo.
              </p>
              <ul className="features-list">
                <li><strong>Registro Diario Eficiente:</strong> Documenta medicaciones, signos vitales y observaciones sin esfuerzo, directamente vinculadas a la cuenta de cada residente.</li>
                <li><strong>Integración Inmediata con SOMA:</strong> Toda la información se sincroniza en la plataforma principal, evitando confusiones y permitiendo que el equipo tenga los datos al instante.</li>
                <li><strong>Planes de Cuidado Personalizados:</strong> Accede rápidamente a directrices específicas para cada residente, garantizando un acompañamiento adaptado a sus necesidades.</li>
                <li><strong>Comunicación Interna Simplificada:</strong> Comparte incidencias o sugerencias con otros profesionales de la residencia, manteniendo un flujo de trabajo colaborativo y eficiente.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewModulesSection;
