// ResidencesSection.js
import React from 'react';
import './ResidencesSection.css';

// Importa los logos (en color) para lograr el efecto grayscale con CSS
import residencia1 from '../assets/logos/residencia1.png';
import residencia2 from '../assets/logos/residencia2.png';

// Agrega o quita más logos según tu necesidad

const ResidencesSection = () => {
  const logos = [
    { id: 1, src: residencia1, alt: 'Casa Senior' },
    { id: 2, src: residencia2, alt: 'Centro de Día Senior' },
   
    // ...
  ];

  return (
    <section className="residences-section">
      <div className="container">
        <h2 data-aos="fade-up">Residencias Impulsadas</h2>
        <div className="carousel-container">
          <div className="carousel-track">
            {logos.map((logo) => (
              <div className="slide" key={logo.id}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResidencesSection;
