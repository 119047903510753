// src/pages/TrabajaConNosotros.js
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './TrabajaConNosotros.css'; // Archivo CSS específico (puedes unificar si gustas)

const TrabajaConNosotros = () => {
  // Estados del formulario
  const [formData, setFormData] = useState({
    nombreCompleto: '',
    email: '',
    telefono: '',
    areaInteres: '',
    cvFile: null,
    comentarios: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Inicializa AOS
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true
    });
  }, []);

  // Maneja cambios en inputs
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'cvFile') {
      setFormData({ ...formData, cvFile: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación sencilla
    if (!formData.nombreCompleto || !formData.email || !formData.telefono) {
      alert('Por favor completa los campos requeridos');
      return;
    }

    // Construir formData para envío (por ejemplo, multipart/form-data)
    const formToSend = new FormData();
    formToSend.append('nombreCompleto', formData.nombreCompleto);
    formToSend.append('email', formData.email);
    formToSend.append('telefono', formData.telefono);
    formToSend.append('areaInteres', formData.areaInteres);
    if (formData.cvFile) {
      formToSend.append('cvFile', formData.cvFile);
    }
    formToSend.append('comentarios', formData.comentarios);

    try {
      // Ajusta la URL a tu endpoint real o método de envío
      await fetch('/api/send-email', {
        method: 'POST',
        body: formToSend
      });

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      alert('Ocurrió un error al enviar tu información. Inténtalo más tarde.');
    }
  };

  if (isSubmitted) {
    return (
      <section className="trabaja-container" data-aos="fade-up">
        <h2>¡Gracias por postular!</h2>
        <p>Tu información ha sido enviada. Pronto nos pondremos en contacto contigo.</p>
      </section>
    );
  }

  return (
    <section className="trabaja-container" data-aos="fade-up">
      <h2 className="trabaja-heading">Trabaja con Nosotros</h2>
      <p className="trabaja-description">
        ¿Quieres formar parte de nuestro equipo en <strong>Soluciones Mayores</strong>?
        Completa el siguiente formulario y envíanos tu CV. ¡Estamos en constante
        búsqueda de profesionales talentosos y comprometidos!
      </p>

      <form onSubmit={handleSubmit} className="trabaja-form">
        <div className="form-group">
          <label htmlFor="nombreCompleto">Nombre Completo *</label>
          <input
            type="text"
            id="nombreCompleto"
            name="nombreCompleto"
            value={formData.nombreCompleto}
            onChange={handleChange}
            placeholder="Ej. Juan Pérez"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Correo Electrónico *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="correo@ejemplo.com"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="telefono">Teléfono de Contacto *</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            placeholder="+56 9 1234 5678"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="areaInteres">Área o Cargo de Interés</label>
          <input
            type="text"
            id="areaInteres"
            name="areaInteres"
            value={formData.areaInteres}
            onChange={handleChange}
            placeholder="Ej. Desarrollo de Software, Ventas, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="cvFile">Adjunta tu CV (PDF)</label>
          <input
            type="file"
            id="cvFile"
            name="cvFile"
            accept="application/pdf"
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="comentarios">Comentarios adicionales (opcional)</label>
          <textarea
            id="comentarios"
            name="comentarios"
            rows="3"
            value={formData.comentarios}
            onChange={handleChange}
            placeholder="Cuéntanos algo más sobre ti..."
          />
        </div>

        <div className="submit-btn-container">
          <button type="submit" className="btn-success">
            Enviar
          </button>
        </div>
      </form>
    </section>
  );
};

export default TrabajaConNosotros;
