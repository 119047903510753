// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../assets/img/logo.png';

const Footer = () => {
  return (
    <footer>
      {/* Formas animadas de fondo */}
      <div className="footer-background-shape footer-shape1"></div>
      <div className="footer-background-shape footer-shape2"></div>
      <div className="footer-background-shape footer-shape3"></div>
      <div className="footer-background-shape footer-shape4"></div>
      <div className="footer-background-shape footer-shape5"></div> 
      <div className="footer-top">
        <div className="footer-column">
          <img src={logo} alt="Soluciones Mayores Logo" className="footer-logo" />
          <p>&copy; 2025 Soluciones Mayores. Todos los derechos reservados.</p>
          <p>Powered by Soluciones Mayores</p>
        </div>
        <div className="footer-column">
          <h4>Nosotros</h4>
          <ul>
            <li><Link to="/sobre-nosotros">Qué es Soluciones Mayores</Link></li>
            <li><Link to="/terminos-y-condiciones">Términos y condiciones</Link></li>
            <li><Link to="/politicas-de-privacidad">Política de privacidad</Link></li>
            <li><Link to="/trabaja-con-nosotros">Trabaja con nosotros</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Plataforma</h4>
          <ul>
            <li><Link to="/administracion">SOMA Administrador de Residencias</Link></li>
            <li><Link to="/proyecto-geras">Proyecto Geras</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Partners</h4>
          <ul>
            <li><Link to="/conviertete-en-partner">Conviértete en Partner</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Recursos</h4>
          <ul>
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="/ayuda">Centro de Ayuda</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <ul className="social-media">
          <li><a href="https://www.linkedin.com/company/soluciones-mayores" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
