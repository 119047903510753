// HowItWorksSection.js
import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import './HowItWorksSection.css';

const HowItWorksSection = () => {
  return (
    <section className="how-it-works-section" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="how-it-works-content">
      <div className="howit-shape howit-shape1"></div>
      <div className="howit-shape howit-shape2"></div>
      <div className="howit-shape howit-shape3"></div>
      <div className="howit-shape howit-shape4"></div>
      <div className="howit-shape howit-shape5"></div>
      <div className="howit-shape howit-shape6"></div>


        <h2>
          <ReactTypingEffect
            text={[
              'Simplifica la Gestión de Residencias',
              'Automatiza tus Procesos Administrativos',
              'Enfócate en el Cuidado de tus Residentes'
            ]}
            speed={60}
            eraseSpeed={40}
            typingDelay={500}
            eraseDelay={2000}
          />
        </h2>
        <p>
          En <strong>Soluciones Mayores</strong>, entendemos que el cuidado de los adultos mayores exige dedicación, experiencia y, sobre todo, un profundo compromiso con su bienestar. Con esa visión en mente, hemos creado <strong>SOMA</strong>, diseñado para transformar la gestión y administración de las Residencias de Adulto Mayor (ELEAM) en todo el país.
        </p>
        <p>
          <strong>SOMA</strong> nace de nuestra experiencia directa en la operación de una residencia de adulto mayor, lo que nos ha permitido identificar los principales desafíos clínicos y administrativos que enfrentan estos centros. Gracias a esta cercanía, hemos desarrollado una plataforma capaz de simplificar tareas diarias, centralizar información de cada residente y, en última instancia, liberar tiempo valioso para que tu equipo pueda concentrarse en lo que más importa: brindar una atención de calidad, personalizada y humana.
        </p>
        <p>
          La flexibilidad de <strong>SOMA</strong> permite adaptarla a residencias de distintos tamaños y necesidades. Nuestra meta es empoderar a las instituciones para que puedan optimizar procesos, reducir costos operativos y, sobre todo, mejorar la calidad de vida de cada persona mayor que confía en ellas. 
        </p>
        <p>
          A continuación, descubre cómo <strong>SOMA</strong> puede revolucionar la operación de tu residencia y mejorar la calidad de vida de tus residentes.
        </p>
      </div>
    </section>
  );
};

export default HowItWorksSection;
