// WelcomeSection.js
import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import './WelcomeSection.css';

const WelcomeSection = () => {
  return (
    <section className="welcome-section">
      <div className="welcome-content">
        <h1>Bienvenidos a Soluciones Mayores</h1>
        <p>
          <ReactTypingEffect
            text={[
              'Soluciones para el Cuidado.',
              'Tecnologías para el futuro.',
              'Optimizando la gestión de tu residencia.'
            ]}
            speed={60}
            eraseSpeed={40}
            typingDelay={500}
            eraseDelay={2000}
          />
        </p>
        <a href="/contacto" className="btn-welcome">Contáctanos</a>
      </div>
    </section>
  );
};

export default WelcomeSection;
