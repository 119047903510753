// RegistroResidenciaPage.js (mejorado)
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactTypingEffect from 'react-typing-effect';
import './RegistroResidenciaPage.css';

const RegistroResidenciaPage = () => {
  const [contactInfo, setContactInfo] = useState({ nombre: '', email: '', telefono: '' });
  const [residencias, setResidencias] = useState([{ nombreResidencia: '', numeroResidentes: '', comuna: '', region: '', pais: '' }]);
  const [formEnviado, setFormEnviado] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 800, easing: 'ease-in-out', once: true });
  }, []);

  const handleContactChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const handleResidenciaChange = (index, e) => {
    const { name, value } = e.target;
    const nuevasResidencias = [...residencias];
    nuevasResidencias[index][name] = value;
    setResidencias(nuevasResidencias);
  };

  const agregarResidencia = () => {
    setResidencias([...residencias, { nombreResidencia: '', numeroResidentes: '', comuna: '', region: '', pais: '' }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormEnviado(true);
  };

  return (
    <section className="registro-page container" data-aos="fade-up">
      <div className="registro-page-header-spacer"></div>
      <h2 className="registro-heading text-center mb-4 animate__animated animate__fadeInDown">
        <ReactTypingEffect
          text={[
            'Registra tu Residencia',
            'Conéctate con Soluciones Mayores',
            'Facilita tu Gestión Residencial'
          ]}
          speed={60}
          eraseSpeed={40}
          typingDelay={500}
          eraseDelay={2000}
        />
      </h2>

      {formEnviado ? (
        <div className="resultado-cotizacion bg-white p-4 rounded shadow-md text-center animate__animated animate__fadeIn">
          <h3>¡Gracias por tu información!</h3>
          <p className="mt-2">
            Pronto nos pondremos en contacto contigo para conocer más sobre tu residencia.
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="registro-form shadow-md rounded bg-white animate__animated animate__fadeInUp">
          <div className="form-section">
            <h3 className="section-title">Información de Contacto</h3>
            <div className="form-group">
              <label htmlFor="nombre">Nombre Completo</label>
              <input type="text" name="nombre" id="nombre" placeholder="Ej. Juan Pérez" value={contactInfo.nombre} onChange={handleContactChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input type="email" name="email" id="email" placeholder="correo@ejemplo.com" value={contactInfo.email} onChange={handleContactChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="telefono">Teléfono / Celular</label>
              <input type="tel" name="telefono" id="telefono" placeholder="+56 9 1234 5678" value={contactInfo.telefono} onChange={handleContactChange} required />
            </div>
          </div>

          <div className="form-section">
            <h3 className="section-title">Datos de la(s) Residencia(s)</h3>
            {residencias.map((residencia, index) => (
              <div key={index} className="residencia-block">
                <h4>Residencia {index + 1}</h4>
                <div className="form-group">
                  <label htmlFor={`nombreResidencia-${index}`}>Nombre de la Residencia</label>
                  <input type="text" name="nombreResidencia" id={`nombreResidencia-${index}`} placeholder="Ej. Residencia Primavera" value={residencia.nombreResidencia} onChange={(e) => handleResidenciaChange(index, e)} required />
                </div>
                <div className="form-group">
                  <label htmlFor={`numeroResidentes-${index}`}>Número de Residentes</label>
                  <input type="number" name="numeroResidentes" id={`numeroResidentes-${index}`} placeholder="Ej. 25" value={residencia.numeroResidentes} onChange={(e) => handleResidenciaChange(index, e)} required />
                </div>
                <div className="form-group">
                  <label htmlFor={`comuna-${index}`}>Comuna</label>
                  <input type="text" name="comuna" id={`comuna-${index}`} placeholder="Ej. Santiago Centro" value={residencia.comuna} onChange={(e) => handleResidenciaChange(index, e)} required />
                </div>
                <div className="form-group">
                  <label htmlFor={`region-${index}`}>Región</label>
                  <input type="text" name="region" id={`region-${index}`} placeholder="Ej. Región Metropolitana" value={residencia.region} onChange={(e) => handleResidenciaChange(index, e)} required />
                </div>
                <div className="form-group">
                  <label htmlFor={`pais-${index}`}>País</label>
                  <input type="text" name="pais" id={`pais-${index}`} placeholder="Ej. Chile" value={residencia.pais} onChange={(e) => handleResidenciaChange(index, e)} required />
                </div>
              </div>
            ))}
            <button type="button" className="btn-outline-success agregar-residencia-btn" onClick={agregarResidencia}>
              + Agregar otra Residencia
            </button>
          </div>

          <div className="text-center mt-4">
            <button type="submit" className="btn-success px-4 py-2">
              Enviar Información
            </button>
          </div>
        </form>
      )}
    </section>
  );
};

export default RegistroResidenciaPage;
