// MainSection1.js
import React from 'react';
import './MainSection.css';
import hoteleriaHabitacionesVideo from '../assets/videos/video1.mp4'; // Importa el video

const MainSection1 = () => {
  return (
    <section id="servicios"className="main-section"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="section-content">
        <div className="video-container" data-aos="fade-right">
          <video autoPlay loop muted playsInline>
            <source src={hoteleriaHabitacionesVideo} type="video/mp4" />
            Tu navegador no soporta la etiqueta de video.
          </video>
        </div>
        <div className="text-container" data-aos="fade-left">
          <h2>Modulo Habitaciones</h2>
          <p>
            <strong>SOMA</strong> Optimiza la gestión de habitaciones y servicios hoteleros siendo una plataforma intuitiva.
          </p>
          <ul>
            <li>Estadisticas de Ocupación</li>
            <li>Asignación eficiente de habitaciones</li>
            <li>Control de reservas y disponibilidad</li>
            <li>Gestión de servicios adicionales</li>
            <li>Información completa de habitaciones</li>
            <li>Reportes personalizados</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MainSection1;
